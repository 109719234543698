import { MoreHorizontal, Trash2, XCircle } from "react-feather";
import { Dropdown, DropdownItem } from "semantic-ui-react";
import { SmartTargetStatus } from "../../../types/types";

import "./SmartTargetTableDropdown.scss";

const runningStatuses = [SmartTargetStatus.RUNNING, SmartTargetStatus.PENDING];

interface SmartTargetTableDropdownProps {
	smartTargetStatus: SmartTargetStatus;
	onCancel: () => void;
	onDelete: () => void;
}

export default function SmartTargetTableDropdown({
	smartTargetStatus,
	onCancel,
	onDelete,
}: SmartTargetTableDropdownProps) {
	return (
		<Dropdown icon={<MoreHorizontal color="#767676" />}>
			<Dropdown.Menu>
				{runningStatuses.includes(smartTargetStatus) && (
					<DropdownItem text="Cancel Target" icon={<XCircle size={"1rem"} />} onClick={() => onCancel()} />
				)}
				{!runningStatuses.includes(smartTargetStatus) && (
					<DropdownItem text="Delete Target" icon={<Trash2 size={"1rem"} />} onClick={() => onDelete()} />
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
}
