import { Point } from "geojson";
import { SmartTargetComparison } from "../components/CustomImprovements/SmartTargets/SmartTargetFilterItem";
import Property, { PropertyResponse } from "../rest/Property";
import Role from "../rest/Role";

export type KeyValuePair = { [key: string]: string | number | boolean | null };

export interface TimestampedRecord {
	id: number;
	created_at: string;
	updated_at: string;
}

export interface Group {
	id: number;
	name: string;
	description?: string;
	date: string;
	is_favourite?: boolean;
	inserted_at: string;
}

export interface CustomImprovement {
	id: number;
	name: string;
	description: string;
	accepts_parameters: boolean;
	min?: number;
	max?: number;
	units?: string;
}

export interface SapImprovementTypes {
	energy_saving: number;
	co2_saving: number;
	cost_saving: number;
	sap_value: number;
	sap_rating: string;
	co2_value: number;
	co2_rating: string;
	heat_demand: number;
	hlp: number;
}

export interface SapImprovementResults extends TimestampedRecord, SapImprovementTypes {
	custom_improvement_sets_id: number;
}

export interface CustomImprovementsSet {
	id: number;
	name: string;
	result: SapImprovementResults;
}

export interface CustomImprovementSetWithImprovement extends CustomImprovementsSet {
	custom_improvements: CustomImprovementSetHasCustomImprovement[];
}

export interface CustomImprovementSetHasCustomImprovement {
	custom_improvement_sets_id: number;
	custom_improvements_id: number;
	improvement: CustomImprovement;
	parameters?: string;
	order?: number;
	reason?: string;
	result?: SapImprovementResults;
}

export interface Batch {
	id: string;
	progress: number;
	pending_jobs: number;
	failed_jobs: number;
	total_jobs: number;
	created_at: number;
	cancelled_at: number;
	finished_at: number;
}

export interface BulkCustomImprovementSetsBatch extends Batch {
	options: {
		team_id: number;
	};
}

export interface BulkCustomImprovementsAggregate {
	progress: number;
	total_jobs: number;
	total_pending: number;
	total_failed: number;
	cancelled_at: number | null;
	finished_at: number | null;
}

export interface CustomImprovementsModalPanelProps {
	teamId: number;
	property?: Property;
	data?: any;
	onClose: Function;
	onComplete?: Function;
}

export interface PropertyFeature {
	type: string;
	geometry: Point;
	properties: {
		data: PropertyResponse;
		epc_rating: number; // NB: For layer styling
	};
}

export interface User {
	id: number;
	team_id: number;
	is_admin: boolean;
	email: string;
	role: Role;
	picture: string;
	nickname: string;
}

/** Columns that are able to be matched with a LIKE clause */
interface LikeMatchColumns {
	uprn?: string | string[];
	addr_no?: string | string[];
	addr_street?: string | string[];
	addr_city?: string | string[];
	postcode?: string | string[];
	no_of_storeys?: string | string[];
	region?: number | number[];
	lat?: number | number[];
	lng?: number | number[];
	age?: string | string[];
	detachment?: string | string[];
	property_type?: string | string[];
	wall_type?: string | string[];
	property_description?: string | string[];
	block_ref?: string | string[];
	scheme_ref?: string | string[];
	notes_1?: string | string[];
	notes_2?: string | string[];
}

/** Columns that are able to be matched with an = */
interface EqualsMatchColumns {
	construction_age?: number | number[];
	roof_type?: number | number[];
	fuel_type?: string | string[];
}

/** Numeric columns that are able to be matched between two values */
interface BetweenMatchColumns {
	epc_rating?: [number, number];
	data_integrity?: [number, number];
	current_heat_demand?: [number, number];
	potential_heat_demand?: [number, number];
	wall_construction?: [number, number];
	glazing_u_value?: [number, number];
	roof_construction?: [number, number];
	main_fuel_price?: [number, number];
	main_fuel_co2?: [number, number];
	secondary_fuel_price?: [number, number];
	secondary_fuel_co2?: [number, number];
	odc_lodgement_date?: [number, number];
}

/** Filters for querying for properties */
export type PropertyFilters = {
	address?: string;
	epc_rating_grade?: string | string[];
	group_ids?: number[];
	polygons?: string[];
} & LikeMatchColumns &
	EqualsMatchColumns &
	BetweenMatchColumns;

export enum SmartTargetFields {
	ENERGY_SAVING = "energy_saving",
	CO2_SAVING = "co2_saving",
	COST_SAVING = "cost_saving",
	SAP_VALUE = "sap_value",
	CO2_VALUE = "co2_value",
	HEAT_DEMAND = "heat_demand",
	HLP = "hlp",
}

export interface SmartTargetParams {
	target: SmartTargetFields;
	value?: number;
	weights: { [weight in SmartTargetWeight]: boolean };
	filters: SmartTargetFilter[];
}

export enum SmartTargetStatus {
	RUNNING = "running",
	FINISHED = "finished",
	CANCELLED = "cancelled",
	ERROR = "error",
	PENDING = "pending",
}

export interface SmartTarget extends TimestampedRecord {
	target: SmartTargetFields;
	value: number;
	status: SmartTargetStatus;
	custom_improvement_sets?: CustomImprovementsSet[] | CustomImprovementSetWithImprovement[];
	logs?: SmartTargetLogEntry[];
}

export interface SmartTargetLogEntry {
	id: number;
	message: string;
	log_level: number;
	log_time: string;
}

export interface SmartTargetFilter {
	customImprovement: CustomImprovement;
	hidden: boolean;
	included: boolean;
	operator?: SmartTargetComparison;
	value?: number;
}

export type SmartTargetFieldMap = Record<SmartTargetFields, { current?: number; validateAbove: boolean }>;

export type SmartTargetWeight = "cost" | "disruption";

export type SapImprovementUnit = "£" | "kWh" | "Kg" | null;

type UnitTimeFrame = { unit: SapImprovementUnit; timeFrame: "year" | "month" | "week" | "day" | null };
// not exactly a type, feel free to move to somewhere else...
export const sapImprovementUnitsMap: Record<keyof SapImprovementTypes, UnitTimeFrame> = {
	co2_rating: { unit: null, timeFrame: null },
	co2_saving: { unit: "Kg", timeFrame: "year" },
	co2_value: { unit: null, timeFrame: null },
	cost_saving: { unit: "£", timeFrame: "year" },
	energy_saving: { unit: "kWh", timeFrame: "year" },
	heat_demand: { unit: null, timeFrame: null },
	hlp: { unit: null, timeFrame: null },
	sap_rating: { unit: null, timeFrame: null },
	sap_value: { unit: null, timeFrame: null },
};

export const SapImprovementNiceNames: Record<keyof SapImprovementTypes, string> = {
	co2_rating: "CO2 Rating",
	co2_saving: "CO2 Saving",
	co2_value: "CO2 Value",
	cost_saving: "Cost Saving",
	energy_saving: "Energy Saving",
	heat_demand: "Heat Demand",
	hlp: "HLP",
	sap_rating: "SAP Rating",
	sap_value: "SAP Value",
};

export type Connotation = "positive" | "negative" | "neutral";

export {}; // NB: Workaround for "cannot be compiled"
