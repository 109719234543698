import React, { KeyboardEvent } from "react";
import { FormInputProps, Input as SemanticInput } from "semantic-ui-react";
import PropTypes from "prop-types";

import "./Input.scss";

interface InputProps extends FormInputProps {
	placeholder?: string;
}

const Input = (props: InputProps) => {
	const { placeholder, size } = props;

	const preventNonNumericInput = (event: KeyboardEvent<HTMLInputElement>) => {
		if (!/[0-9.]/.test(event.key)) {
			event.preventDefault();
		}
	};

	return (
		<SemanticInput
			className={size ? `story-input--${size}` : "story-input--md"}
			placeholder={placeholder ?? ""}
			onKeyPress={props.type === "number" ? preventNonNumericInput : undefined}
			{...props}
		/>
	);
};

export default Input;

Input.propTypes = {
	/**
	 * How large should the button be?
	 **/
	size: PropTypes.oneOf(["sm", "md", "lg"]),
};
