import React, { useState } from "react";

import TeamSelect from "../TeamSelect/TeamSelect";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import { useUserContext } from "../../../contexts/UserContext";

import "./CurrentTeamSelect.scss";
import { useTeamContext } from "../../../contexts/TeamContext";

const CurrentTeamSelect = () => {
	const client = useRestClientContext();
	const user = useUserContext();
	const team = useTeamContext();
	const [loading, setLoading] = useState(false);

	const onTeamChange = (value: number) => {
		setLoading(true);

		client
			.updateUser(user.id, {
				team_id: value
			})
			.then(() => {
				window.location.reload();
			});
	};

	return (
		<TeamSelect
			className="current-team-select"
			placeholder="Current Team"
			loading={loading}
			hideWhenNoChoices={true}
			showWithZeroProperties={!team.properties_count}
			onChange={onTeamChange}
		/>
	);
};

export default CurrentTeamSelect;
