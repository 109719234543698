import DirectionArrowVector from "../../../assets/DirectionArrowVector";
import {
	Connotation,
	SapImprovementNiceNames,
	SapImprovementTypes,
	sapImprovementUnitsMap,
} from "../../../types/types";
import ChipLabel from "../../UI/ChipLabel/ChipLabel";

interface SmartTargetValueChipProps {
	type: keyof SapImprovementTypes;
	value: number | null;
	direction: boolean | null;
	connotation?: Connotation;
}

const POSITIVE_COLOUR = "#03C52E";
const NEGATIVE_COLOUR = "#DB042C";

export default function SmartTargetValueChip({ type, value, direction, connotation }: SmartTargetValueChipProps) {
	const unit = sapImprovementUnitsMap[type].unit;

	if (!connotation) {
		// assume from the direction
		connotation = direction === true ? "positive" : direction === false ? "negative" : "neutral";
	}

	const icon =
		direction != null ? (
			<DirectionArrowVector
				rotation={direction ? 0 : 180}
				colour={connotation === "positive" ? POSITIVE_COLOUR : NEGATIVE_COLOUR}
			/>
		) : null;

	const generateLabel = () => {
		if (value === null) {
			return `${SapImprovementNiceNames[type]}: N/A`;
		}

		return unit === "£"
			? `${SapImprovementNiceNames[type]}: £${value.toFixed(2)}`
			: unit === null
			? `${SapImprovementNiceNames[type]}: ${value.toFixed(0)}`
			: `${SapImprovementNiceNames[type]}: ${value.toFixed(0)} ${unit}`;
	};

	return <ChipLabel label={generateLabel()} rounded="square" variant={connotation} icon={icon} />;
}
